import * as React from "react";
import { Spinner, SpinnerSize } from "@blueprintjs/core";   

const SecondaryContainer = (props) => {

    console.log('SecondaryContainer.props:', props);

    if(props.params.secondaryComponent){
        const Component = globalThis['bodaproComponents'][`${props.params.secondaryComponent}`];
        return <Component {...props}/>
    }

    return (
        <div className="bp5-dark p-2">
            <Spinner size={SpinnerSize.LARGE}/>
        </div>
    );
}

export default SecondaryContainer;