import { max } from 'date-fns-jalali';
import { 
    ADD_LOG_MESSAGE,
    CLEAR_LOG_MESSAGES
} from '../actions/log-actions';

const initialState = {
    messages: [],
    max: 200,
};

const logReducer = (state = initialState, action) => {
    switch(action.type) {
        case ADD_LOG_MESSAGE:
            if (state.messages.length >= state.max) {
                state.messages.shift();
            }
            return {
                ...state,
                messages: [...state.messages, action.message]
            };
        case CLEAR_LOG_MESSAGES:
            return {
                ...state,
                messages: []
            };
        default:
            return state;
    }
}

export default logReducer;