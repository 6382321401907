import * as React from 'react';
import { Spinner, SpinnerSize} from '@blueprintjs/core';
import { IDockviewPanelHeaderProps, DockviewDefaultTab } from 'dockview';

const SecondaryTabContainer = (props) => {
    props.api.setTitle(props.params.secondaryTabLabel ? props.params.secondaryTabLabel : 'Docs');

    if(props.params.secondaryTabLabel){
        return <DockviewDefaultTab {...props} hideClose={true} />;

    // const onContextMenu = (event) => {
    //     event.preventDefault();
    };

    return (
        <div className="bp5-dark p-2">
            <Spinner size={SpinnerSize.SMALL}/>
        </div>
    )
}

export default SecondaryTabContainer;