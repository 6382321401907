import * as React from 'react';
import { Spinner, SpinnerSize} from '@blueprintjs/core';

const ExtensionWelcomeContainer = (props) => {
    if(props.params.welcomeComponent){
        const Component = globalThis['bodaproComponents'][`${props.params.welcomeComponent}`];
        return <Component {...props}/>
    }

    return (
        <div className="bp5-dark p-2">
            <div style={{marginTop: '50px'}}></div>
            <Spinner size={SpinnerSize.LARGE}/>
        </div>
    )
}

export default ExtensionWelcomeContainer;