import React, { useEffect } from 'react';
import LoginForm from './modules/session/LoginFormExtension';
import ConfiguringApp from './modules/session/ConfiguringApp';
import UILayout from './modules/layoutv2/UILayoutExtension';
import { useSelector, useDispatch } from 'react-redux';
import ErrorBoundary from './modules/layout/ErrorBoundary';
import VERSION from './version';
import { resetState } from './modules/session/session-actions'
import { useParams } from 'react-router-dom';
import { Spinner, SpinnerSize, Text } from '@blueprintjs/core';
import { getExtensionConfig, DOWNLOAD_ASSETS_URL, EXTENSION_ASSETS_URL } from './services/api'
import { logIntoApp } from './modules/session/session-actions';

if(!window.bodapro){
	window.bodapro = {
		// platformType: process.versions['electron'] ? 'desktop': 'web',
		platformType: window && window.process && window.process.type ? 'desktop': 'web',
		dbQuery : () => false,
		migrateUp: () => {},
		submitCode: () => {},
		addToLog: () => {},
		getInstalledExtensions: () => {},
		api : {},
		fs: { existsSync: (v) => true },
		runWorker: () => {}, //@todo: implement web socket for server implementation
		listenOn: () => {},
	};

}

const AppExtension = () => {
	const [loading, setLoading] = React.useState(true);
	const { authenticated, version, updating } = useSelector((state) => state.session);
	const [ error,setError ] = React.useState(null);
	const [ errorInfo,setErrorInfo ] = React.useState(null);
	const dispatch = useDispatch();
	let { extension } = useParams();
	const [extIconUrl, setExtIconUrl] = React.useState(null);
	const [extConfig, setExtConfig] = React.useState(null);
	const [requiresAuth, setRequiresAuth] = React.useState(true);

	console.log('extension:', extension); 

	const loadUserDetails = (extCfg) => {
		const userDetails = localStorage.getItem(`${extCfg.id}:userDetails`);
		if(userDetails){
			dispatch(logIntoApp(JSON.parse(userDetails)));
		}
	}

	const setFavicon = (url) => {
		var link = document.querySelector("link[rel~='icon']");
		if (!link) {
			link = document.createElement('link');
			link.rel = 'icon';
			document.head.appendChild(link);
		}
		link.href =  EXTENSION_ASSETS_URL + `/${extension}/assets/favicon.ico`;
		console.log('Favicon:', link.href);
	}

	useEffect(() => {
		VERSION !== version && dispatch(resetState());


		const fn = async () => {
            try {
                const config = await getExtensionConfig(extension);
                
                setExtConfig(config);
                setExtIconUrl(config?.webOptions.favicon);

				//set window title
				document.title = config?.label || 'BodaPro';

				//check if authentication is needed 
				if(config?.webOptions?.requiresAuth){
					setRequiresAuth(true);

					
				}else{
					setRequiresAuth(false);
				}

				loadUserDetails(config);        

            } catch (e) {

            }

            setLoading(false);
        }

        fn();

	},[1]);

	//show updating skin
	// if(updating) {
	// 	return <ConfiguringApp />;
	// }

	// authentication
	if(window.bodapro?.platformType == 'web'){//show authentication for web

		setFavicon(extIconUrl);

		//@todo: load single extension
		if(extension === undefined || extConfig === null){
			return <div className="bp5-dark" 
						style={{
							marginTop: '100px',
							'textAlign': 'center'
						}}>
				<Spinner size={SpinnerSize.LARGE}/>
				<span><Text>Loading ...</Text></span>
			</div>
		}

		if (requiresAuth && authenticated === false) {
			return (
				<ErrorBoundary>
					<LoginForm extConfig={extConfig}/>
				</ErrorBoundary>
			);
		}
	}


	return (
		<ErrorBoundary>
			<UILayout className="bp5-dark" extConfig={extConfig}/>
		</ErrorBoundary>
	);
}

export default AppExtension;