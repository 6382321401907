import React, { lazy } from 'react';
import {
    DockviewReact,
    DockviewDefaultTab
} from 'dockview';
import ActivityBar from './ActivityBar'
import 'dockview/dist/styles/dockview.css';
import Welcome from '../welcome';
import WelcomeContainer from '../welcome/ExtensionWelcomeContainer';
import ExtWelcomeTabContainer from '../welcome/ExtWelcomeTabContainer';
import ExtSideBarContainer from '../extensions/ExtSideBarContainer';
import ExtSibebarTab from '../extensions/ExtSibeBarTab';
import StatusBar from './StatusBar';
import { Icon, Spinner, SpinnerSize } from '@blueprintjs/core';
import * as ReactLeaflet from 'react-leaflet';
import MonacoEditor from 'react-monaco-editor';
import { Observable, from, Subject, share } from 'rxjs';
import { FullscreenControl } from "react-leaflet-fullscreen";
import  HelpSideBar from '../help/HelpSideBar';
import ExtensionSidebar from '../extensions/ExtensionSideBar';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as FAFreeBrandSVGIcons from '@fortawesome/free-brands-svg-icons';
import * as FAFreeSolidSVGIcons from '@fortawesome/free-solid-svg-icons';
import { EXTENSION_ASSETS_URL, fetchUserExtensions } from '../../services/api';
import { useSelector, useDispatch } from 'react-redux';
import ExtensionInfo from '../extensions/ExtensionInfo'
import { logOutOfApp } from '../session/session-actions';
import SecondaryContainer from './SecondaryContainer';
import SecondaryTabContainer from './SecondaryTabContainer';

const UILayoutExtension = (props) => {
    const dispatch = useDispatch();
    const { extConfig } = props;
    const { userDetails } = useSelector(state => state.session);
    const GROUPS = ['primary-sidebar', 'main-panel', 'bottom-panel', 'secondary-sidebar', 'status-bar', 'active-bar'];
    const [api, setApi] = React.useState();

    const [refreshKey, setRefreshKey] = React.useState(0);
    // let layoutEvent = null;
    const [ layoutEvent, setLayoutEvent ] = React.useState(null);
    const [ commands$, setCommands$ ] = React.useState({
        'layout-ready': new Subject()
    });

    const [componentList, setComponentList] = React.useState({
        // activityBar: ActivityBar,
        loading: (props) => <div className="bp5-dark" style={{marginTop: '50px'}}><Spinner SpinnerSize={40}></Spinner></div>,
        activityBar: (props) => <ActivityBar contributions={[]} {...props} commands$={commands$} getCommands$={function () { return commands$;}}/>,
        // welcomePanel: Welcome,
        welcomePanel: WelcomeContainer,
        primarySideBar: ExtSideBarContainer,
        statusBar: StatusBar,
        // helpSideBar: HelpSideBar,
        secondaryContainer: SecondaryContainer,
        extensionSideBar: ExtensionSidebar,
        'extension-info': ExtensionInfo,

        // default: (props: IDockviewPanelProps<{ title: string }>) => {
        default: (props) => {
            return (
                <div style={{ padding: '20px', color: 'white' }}>
                    { //props.params.title
                    }
                </div>
            );
        },
    });

    //save layout
    // React.useEffect(() => {
    //     if(!api) {
    //         return;
    //     }
        
    //     const disposable = api.onDidLayoutChange(() => {
    //         const layout = api.toJSON();
    //         console.log('layout:', layout);
    //         // localStorage.setItem('bodapro_layout', JSON.stringify(layout));
    //     });
        
    //     return () => disposable.dispose();
    // }, [api]);

    //globalThis
    globalThis['commands$'] = globalThis['commands$'] || commands$;
    globalThis['bodaproComponents'] = globalThis['bodaproComponents']  || componentList;
    // globalThis['bodaproComponents'] = globalThis['bodaproComponents']  || {};
    //Panel components
    let components = {};
    let _extComponents = {};
    let _commands = {};
    let tabs = {};
  
        //setup bodaContext
    //---------------------------------------------------
    window['bodapro-context'] = {
        //bts api 
        api: bodapro,

        commands$: commands$,

        registerCommand: function (name, callback) {
            const observable = new Subject();
            observable.subscribe((args) => {
                if( typeof callback === 'function') callback(args);
            });

            setCommands$({
                ...commands$,
                [name]: observable
            });

            _commands[name] = observable;

            setCommands$({ ...commands$, ..._commands});
            // console.log('_commands:', _commands);
            globalThis['commands$'] = { ...globalThis['commands$'], ..._commands};
        },

        registerComponent: function (component) {

            //@todo: check and only allow welcomePanel if this is a standalone extension

            components[component.id] = (panelProps) => <component.component 
                ReactLeaflet={ReactLeaflet} 
                FullscreenControl={FullscreenControl}
                MonacoEditor={MonacoEditor}
                FontAwesomeIcon={FontAwesomeIcon}
                FAFreeSolidSVGIcons={FAFreeSolidSVGIcons}
                FAFreeBrandSVGIcons={FAFreeBrandSVGIcons}
                panelProps={panelProps}
                />;

            setComponentList({ ...componentList, ...components});
            globalThis['bodaproComponents'] = { ...globalThis['bodaproComponents'], ...components};

            console.log('component:', component);
        },

        //trigger command
        triggerCommand: function (commandName, args) {
            if(globalThis.commands$[commandName] !== undefined) globalThis.commands$[commandName].next(args);
        },

        //subscribe to command
        subscribeToCommand: function (commandName, callback) {

            //add delay to make sure the command is available
            setTimeout(() => {
                if(globalThis.commands$[commandName] !== undefined) globalThis.commands$[commandName].subscribe((args) => {
                    if( typeof callback === 'function') callback(args);
                });
            }, 10);

        },

        addPanel: function ({id, title, componentName, groupId, extraProps, params, replacePanel, isPrimarySidePanel}) {
            if(layoutEvent.api.getPanel(id) !== undefined && replacePanel !== true) {
                layoutEvent.api.getPanel(id).api.setActive();
                return;
            }

            //get primary side panel group 
            const panelGroup = (groupId !== undefined && GROUPS.indexOf(groupId) > -1) ? layoutEvent.api.getPanel(groupId).api.group : layoutEvent.api.getPanel('primary-sidebar').api.group;

            //clear current panel
            if(replacePanel && layoutEvent.api.getPanel(id) !== undefined){
                const _panel = layoutEvent.api.getPanel(id);
                layoutEvent.api.removePanel(_panel);
            }

            //
            if(isPrimarySidePanel){

            }

            console.log('group:', panelGroup);

            const newPanel = layoutEvent.api.addPanel({
                id: id,
                component: componentName,
                tabComponent: isPrimarySidePanel ? 'panelTab' : 'default',
                title: title,
                position: { referenceGroup: panelGroup },
                ...extraProps,
                params
            });
            newPanel.group.hideClose = true;
            


        },

        setWelcomePanel: function (componentName) {
            console.log('setWelcomePanel -- componentName:', componentName);
            layoutEvent.api.getPanel('main-panel').api.updateParameters({componentName});
        },

        /**
         * Set the parimary side bar 
         * 
         * @param {} componentName 
         */
        setSideBarPanel: function (componentName) {
            layoutEvent.api.getPanel('primary-sidebar').api.updateParameters({sideBarComponent: componentName});
        },

        updatePanelParams: function (panelId, params) {
            layoutEvent.api.getPanel(panelId).api.updateParameters(params);
            console.log('updatePanelParams:', panelId, params);
        }

    };
    //eof: bodapro context---------------------------------------------------------------------------------------------------


    //registr logout  command 
    //--------------------------------------------------------
    // window['bodapro-context'].registerCommand('bodapro:logout', function () {
    //     // dispatch(logOutOfApp());
    // });
    window['bodapro-context'].api.logout = () => {
        dispatch(logOutOfApp());
    };
    
    const fn = async () => {

        if(layoutEvent === null) return;

        // get extension list  
        let extensions =  [];

        // let _extComponents = {};
        // let _commands = {};
        let _contributions = [];

        //---WEB extensions
        //--------------------------------------------------------
        if(bodapro?.platformType === 'web'){
            // const ext = await import('file:./extensions/extension1/dist/extension1.js');
            extensions = []; //await fetchUserExtensions(userDetails?.token);    
            
            const entryURL  = `${EXTENSION_ASSETS_URL}/${extConfig.name}/entry.js`;
            const icon = `${EXTENSION_ASSETS_URL}/${extConfig.name}/assets/${extConfig.name}.svg`;
            // const someThing = await import(/* webpackIgnore: true */ ext.url);
            const someThing = await import(/* webpackIgnore: true */ entryURL);

            _contributions.push({
                'id': extConfig.id, //extensionPackage.id,
                'name': extConfig.name, //extensionPackage.name,
                'label': extConfig.name, //extensionPackage.label,
                'version': extConfig.version, //extensionPackage.version,
                'description': extConfig.description, //extensionPackage.description,
                'icon': icon, //extensionPackage.icon.endsWith('.svg') ? bodapro.path.join(extDir, extensionPackage.icon) : extensionPackage.icon,
            });

            const importExt = someThing.default({standalone: true}); //----

            setCommands$({ ...commands$, ..._commands});
            globalThis['commands$'] = { ...globalThis['commands$'], ..._commands};
            setActivityBarContributions(_contributions);

            //update the component list
            setComponentList(globalThis['bodaproComponents']);

            let cmdCheckInt = null;
            cmdCheckInt = setInterval(() => {
                // console.log('extConfig.id:', extConfig.id);
                // console.log('extConfig.name:', extConfig.name);
                // console.log('globalThis:', globalThis);
                // console.log('cmdCheckInt:', cmdCheckInt);
                // console.log('globalThis.commands$[extConfig.id]', globalThis.commands$[extConfig.id])
                if(globalThis.commands$[extConfig.id] !== undefined) {
                    globalThis.commands$[extConfig.id].next(extConfig.name);
                    clearInterval(cmdCheckInt);
                }
            }, 2000);
            

            return;
        }
        //end of web|online ------------------------------------------------------ONLINE

        // console.log('000000000000000');
        // console.log('extensions:', extensions);
        // if(extensions === undefined) return;


        

        return;

        //---desktop extensions
        //--------------------------------------------------------
        extensions = await bodapro.getInstalledExtensions();
        let idx = 0;
        for (const extension of extensions) {
            idx++;
            //const packageJson = path.join(extension, 'package.json');
            const extensionPackage = bodapro.require(extension);
            const extDir = bodapro.path.dirname(extension);

            const entryFile = bodapro.path.join(extDir,extensionPackage.entry);

            //check if entry files exists 
            if(!bodapro.fs.existsSync(entryFile)) continue;
            _contributions.push({
                'id': extensionPackage.id,
                'name': extensionPackage.name,
                'label': extensionPackage.label,
                'version': extensionPackage.version,
                'description': extensionPackage.description,
                'icon': extensionPackage.icon.endsWith('.svg') ? bodapro.path.join(extDir, extensionPackage.icon) : extensionPackage.icon,
            });
            
            // (async () => {
                try{
                    const someThing = await import(/* webpackIgnore: true */`file:${entryFile}`);
                    const importExt = someThing.default();
                // importExt.activate()
                }catch(e){
                    console.log('error:', e);
                }
            // })();
        }

        setCommands$({ ...commands$, ..._commands});
        globalThis['commands$'] = { ...globalThis['commands$'], ..._commands};
        setComponentList(globalThis['bodaproComponents']);

        setActivityBarContributions(_contributions);
    }

    //activityBar contributions 
    const [ activityBarContributions, setActivityBarContributions ] = React.useState([]);

    //Load extensions 
    React.useEffect(  () => {
        fn();
    }, [layoutEvent !== null]);  


React.useEffect(() => {

    if(layoutEvent === null){
        return;
    }
    const actvitiyBarPanel = layoutEvent.api.getPanel('active-bar');
    actvitiyBarPanel.api.updateParameters({
        contributions: activityBarContributions,
        'keyA': 'Testing A'
    });

}, [activityBarContributions.length, layoutEvent !== null]);

    const PanelTab = (props) => {
        const onContextMenu = (event) => {
            event.preventDefault();
        };
        return <DockviewDefaultTab onContextMenu={onContextMenu} {...props} hideClose={true} />;
    };

    const BottomTab = (props) => {
        const onContextMenu = (event) => {
            event.preventDefault();
            alert('context menu');
        };
        return <DockviewDefaultTab onContextMenu={onContextMenu} {...props} hideClose={true}/>;
    };

    const RightHeaderActions = (props) => {
        return (<div 
            className='bp5-dark'
            style={{
                padding: 5,
                paddingTop: 10
            }}
            >
            <Icon icon="more" intent="secondary" />
        </div>);
    }

    //panel contents 
    components = {
        // activityBar: ActivityBar,
        activityBar: (props) => <ActivityBar contributions={[]} {...props} commands$={commands$} getCommands$={function () { return commands$;}}/>,
        welcomePanel: Welcome,
        welcomeTabContainer: ExtWelcomeTabContainer,
        statusBar: StatusBar,
        'extension-info': ExtensionInfo,
        'secondaryContainer': SecondaryContainer,

        // default: (props: IDockviewPanelProps<{ title: string }>) => {
        default: (props) => {
            return (
                <div style={{ padding: '20px', color: 'white' }}>
                    {props.params.title}
                </div>
            );
        },
    };

    //penal tabs 
    // let tabComponents = {
    //     panelTab: PanelTab,
    //     welcomeTab: PanelTab,
    //     outputTab: BottomTab
    // };

    const [ tabComponents, setTabComponents ] = React.useState({
        loading: (props) => <div className="bp5-dark" style={{textAlign: 'center'}}></div>,
        panelTab: PanelTab,
        // welcomeTab: PanelTab,
        welcomeTab: ExtWelcomeTabContainer,
        secondaryTabContainer: SecondaryTabContainer,
        outputTab: BottomTab,
        extensionSideBar: ExtSibebarTab,
        docsSideBar: PanelTab,
        noCloseTab: PanelTab,
    });

    // const onReady = (event: DockviewReadyEvent) => {
    const onReady = (event) => {
        setApi(event.api);

        console.log('event:', event);
        console.log('event.api:', event.api);

        //set layout dockview event
        // layoutEvent = event;
        setLayoutEvent(event);

        //activitybar
        const activeBar = event.api.addPanel({
            id: 'active-bar',
            component: 'activityBar',
            params: {
                title: 'Active Bar',
                contributions: []
            },
        });
        activeBar.group.locked = true;
        activeBar.group.header.hidden = true;
        activeBar.api.group.api.setConstraints({
            maximumWidth: 0,
            minimumWidth: 0,
        });
  
        //status bar 
        //----------------------------
        const statusBar = event.api.addPanel({
            id: 'status-bar',
            component: 'statusBar',
            title: 'Status',
            params: {
                title: 'Primary sidebar'
            },
            position: { referencePanel: 'active-bar', direction: 'below' },
        });
        statusBar.group.locked = true;
        statusBar.group.header.hidden = true;
        statusBar.api.group.api.setConstraints({
            maximumHeight: 30,
            minimumHeight: 30,
        });

        //primary sidebar
        // ----------------------------
        // const primarySidebarGroup = event.api.addGroup({
        //     activePanenlId: undefined, //'primary-sidebar',
        //     initialWidth: 300,
        //     id: 'primary-sidebar',
        //     panels: [
        //     ],
        //     direction: 'right',
        //     position: { referencePanel: 'active-bar', direction: 'right' }
        // });
        // primarySidebarGroup.locked = true;
        // primarySidebarGroup.hideClose = true;


        const primarySidebar = event.api.addPanel({
            id: 'primary-sidebar',
            // component: 'extensionSideBar',
            component: 'primarySideBar',
            title: 'EXTENSIONS',
            tabComponent: 'extensionSideBar', 
            params: {
                title: 'Primary sidebar'
            },
            position: { referencePanel: 'active-bar', direction: 'right' },
        });
        primarySidebar.api.setSize({width: 300});
        primarySidebar.group.locked = true;
        primarySidebar.group.hideClose = true;

        //hide primary sidebar if not needed
        if(!extConfig?.webOptions?.panels.includes('primary-sidebar')){
            primarySidebar.api.group.api.setConstraints({
                maximumWidth: 0,
                minimumWidth: 0,
            });
        }else{
            primarySidebar.group.api.setSize({
                width: 300,
            });
        }

        



        //main panel
        //----------------------------
        const mainPanel = event.api.addPanel({
            id: 'main-panel',
            component: 'welcomePanel',
            title: 'Welcome',
            params: {
                title: 'Welcome',
            },
            tabComponent: 'welcomeTab', 
            position: { referencePanel: 'primary-sidebar', direction: 'right' },
        });
        mainPanel.group.locked = true;
        mainPanel.group.hideClose = true;

        //secondry sidebar
        //----------------------------
        const secondarySidebar = event.api.addPanel({
            id: 'secondary-sidebar',
            component: 'secondaryContainer',
            title: 'Documentation',
            tabComponent: 'secondaryTabContainer',
            params: {
                title: 'Documentation',
            },
            position: { referencePanel: 'main-panel', direction: 'right' },
        });
        if(!extConfig?.webOptions?.panels.includes('secondary-sidebar')){
            secondarySidebar.api.group.api.setConstraints({
                maximumWidth: 0,
                minimumWidth: 0,
            });
        }else{
            secondarySidebar.group.api.setSize({
                width: 300,
            });
        }

        //bottom panel
        //----------------------------
        //add if extension needs it 
        if(extConfig?.webOptions?.panels.includes('bottom-panel')){
            const bottomPanel = event.api.addPanel({
                id: 'botton-panel',
                component: 'default',
                title: 'OUTPUT',
                tabComponent: 'outputTab', 
                params: {
                    title: 'Bottom Panel',
                },
                position: { referencePanel: 'main-panel', direction: 'below' },
                
            });
            bottomPanel.api.setSize({height: 100});
        }

        //set the parimary and secondary sidebar sizes again
        //----------------------------
        primarySidebar.api.setSize({width: 400});
        secondarySidebar.api.setSize({width: 300});

    };

    console.log('componentList:', componentList);

    return  <DockviewReact
        components={componentList}
        tabComponents={tabComponents}
        onReady={onReady}
        className={props.theme || 'dockview-theme-dark'}
        rightHeaderActionsComponent={RightHeaderActions}
    />
}

export default UILayoutExtension;