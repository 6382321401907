import React from 'react'
import logo from '../../images/logo.svg';
// import logo from '../../images/logo.png';
import { connect } from 'react-redux';
//import PropTypes from 'prop-types';
import  './LoginForm.css';
import Loading from './Loading';
import { logIntoApp, markLoginAsFailed, authenticateUser, clearAuthError, clearOldSession, checkDBSetupStatus } from './session-actions';
import { Button, Intent, FormGroup, InputGroup } from "@blueprintjs/core";
import VERSION from '../../version';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle, faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import ActivityBarIcon2 from '../../components/ActivityBarIcon2';
import { EXTENSION_ASSETS_URL } from '../../services/api'
import { useDispatch, useSelector  } from 'react-redux';

const  LoginFormExtension = (props ) => {
    const [authInfo, setAuthInfo] = React.useState({
            email: "user@extension.app",
            password: "password"
    });
    const { userDetails, authenticating, loginError, waitingForDB } = useSelector(state => state.session);
    const [authAuthHelper, setAuthHelper] = React.useState(null);

    const dispatch = useDispatch();
    

    React.useEffect(() => {

        const asyncFn = async () => {
            //load the extension webauth.js file
            // const someThing = await import(/* webpackIgnore: true */`file:${entryFile}`);
            const someThing = await import(/* webpackIgnore: true */`${EXTENSION_ASSETS_URL}/${props.extConfig.name}/${props.extConfig.webOptions.authFile}`);
            setAuthHelper(someThing);
        }

        asyncFn();

    },[1]);
    
  
    const handleLoginAdDifferentUser = () => {
        dispatch(clearOldSession())
    }
    
    const dismissError = () => {
        dispatch(clearAuthError());
    }

    const handleSubmit = async (event) => {
        event.preventDefault(); 

        dispatch(authenticateUser());

        if(authAuthHelper){
            const resp = await authAuthHelper.login(authInfo.email, authInfo.password);
            console.log('resp:', resp);

            //authentication fialed. Show error message
            if(resp === false){
                dispatch(markLoginAsFailed("Wrong email and password! Try again."));
                return;
            }

            //success
            if(resp.data){
                localStorage.setItem(`${props.extConfig?.id}:userDetails`, JSON.stringify(resp.data));
                dispatch(logIntoApp(resp.data));

            }else{
                //show error notice

            }
        }else{
            //show error notice
        }

        // 
        
        
    }

    const handleInputChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setAuthInfo({
            ...authInfo,
            [name]: value
        });
    }

    let errorAlertType = waitingForDB === true? 'info' : 'danger';
    
    return (<div className="night">

            <div className="login-mask">
                <div className="shooting_star"></div>
                <div className="shooting_star"></div>
                <div className="shooting_star"></div>

                <div className="login-logo">
                    {props.extConfig.icon ? (<ActivityBarIcon2 icon={`${EXTENSION_ASSETS_URL}/${props.extConfig.name}/${props.extConfig.icon}`} size={100} />) : (<Icon icon="cube" size={100} intent="secondary" />)}
                    <span>
                        <h2  className="bp3-heading bp3-ui-text">{props?.extConfig ? props?.extConfig?.label : 'Extension'}</h2>
                    </span>
                </div>

                <div className="shooting_star"></div>
                <div className="shooting_star"></div>
                <div className="shooting_star"></div>
                
                <form className="form-signin" onSubmit={handleSubmit}>
                    <Loading show={authenticating}/>
                    
                    {loginError == null ? "" :
                        <div className={`alert alert-${errorAlertType} p-2`} role="alert">
                            {loginError}

                            <button type="button" className="btn-close right float-end" data-bs-dismiss="alert" aria-label="Close" onClick={dismissError}></button>
                        </div>
                    }
                    
                    {typeof userDetails === 'undefined' || userDetails === null? 
                        <React.Fragment>
                            <FormGroup
                                label=""
                                labelFor="session_email"
                            >
                                <InputGroup id="session_email" placeholder="Email" 
                                    required={true}
                                    leftIcon="user" 
                                    name="email"
                                    type="email"
                                    value={authInfo.email}
                                    onChange={handleInputChange} />
                            </FormGroup>
                        </React.Fragment>
                    :''}
                    
                    {typeof userDetails === 'undefined' || userDetails === null? '' : 
                        <React.Fragment>
                            <label htmlFor="email" className="sr-only">email</label>
                            <div className="input-group">
                            <span className="font-weight-light">Login as </span>  &nbsp;
                                <span className="font-weight-bold">{userDetails.first_name + ", " +  userDetails.last_name}</span>
                            </div>
                            
                        </React.Fragment>     
                    }
                    
                    <div className="shooting_star"></div>
                <div className="shooting_star"></div>
                <div className="shooting_star"></div>
                    
                        <FormGroup
                            label=""
                            labelFor="session_password"

                        >
                            <InputGroup id="session_password" placeholder="Password" 
                                leftIcon="lock" 
                                name="password"
                                type="password"
                                required={true}
                                value={authInfo.password}
                                onChange={handleInputChange} />
                        </FormGroup>


                        <div style={{flexDirection: 'row'}}>
                            <Button type="submit" text="Sign in" intent={Intent.PRIMARY} disabled={props.authenticating}/> &nbsp;
                            {/* <span style={{textAlign: 'right', flex: 1}}>Create account</span> */}
                        </div>

                        <div style={{borderBottom: 1, borderBottomColor: '#eeeeee'}}></div>
                      

                    {typeof userDetails !== 'undefined' && userDetails !== null? 
                        <a href="/#" onClick={(e) => { e.preventDefault(); handleLoginAdDifferentUser();}}> as different user</a>
                    :''}
                </form>
                <div className="shooting_star"></div>
                <div className="shooting_star"></div>
                <div className="shooting_star"></div>

            </div>

            </div>
        );
}

/*
LoginForm.propTypes = {
    authenticating: PropTypes.bool,
    loginError: PropTypes.string,
    userDetails: PropTypes.object,
    waitingForDB: PropTypes.string
};
*/

// function mapStateToProps(state) {
//   return {
//     authenticating: state.session.authenticating,
//     loginError: state.session.loginError,
//     userDetails: state.session.userDetails,
//     waitingForDB: state.session.waitingForDB
//   }
// }

export default LoginFormExtension;