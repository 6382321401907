import React from 'react';
import { Button, Icon, Intent, ProgressBar, Text } from '@blueprintjs/core';
import { useDispatch, useSelector } from 'react-redux';
import { setInstallingExtension, clearInstallingExtension } from './extensions-actions';
import './extensions.css';

const ResultItem = ({results, search, installedExtensions}) => {
    const [selected, setSelected] = React.useState(null);
    const [context, setContext] = React.useState(null);
    const dispatch = useDispatch();
    const { installingExtensionName, installingExtension } = useSelector(state => state.extensions);
    
    React.useEffect(() => {
        setContext(window['bodapro-context'])
    }, []);


    const isExtensionInstalled = (name) => {
        return globalThis['BODAPRO_EXTENSIONS'].indexOf(name) > -1;
    }

  
  return (
    <div className="mb-20"> {installingExtensionName}
        {results?.filter && results.filter(v => {
            const p = new RegExp(search, 'gi');
            if (search === undefined) return true;
            if (search.length === 0) return true;
            if (p.test(v.name)) return true;
            return false;
        }).map((v, idx) => 
        <div
            onClick={() => {
                context.addPanel({
                    id: 'extension-info',
                    title: v.label,
                    icon: 'cube',
                    componentName: 'extension-info',
                    groupId: 'main-panel',
                    params: {
                        'extension' : v.name,
                        
                    },
                    replacePanel: true
                });

                setSelected(v.name)
            }}
            style={{
                width: '100%',
                marginBottom: 10,
                hover: 'background-color: #f5f5f5'
            }}
            className={`extesion-search-result ${selected === v.name ? 'selected-result-item' : ''}`}
            key={idx}>
                <div style={{float: 'left'}} className="p-2">
                    <Icon icon="cube" size={40}/>
                    {/* {v.name ? (<img src={`${EXTENSION_ASSETS_URL}/${v.name}/assets/${v.name}.svg`} width={40} />) : (<Icon icon="cube" size={40} intent="secondary" />)} */}
                </div>
                <div>
                    <div><span className="bp5-heading">{v.label}</span></div>
                    <div ><Text ellipsize>{v?.description}</Text></div>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <div>
                            <Text className="bp5-heading bp5-text-muted bp5-running-text">{v?.vendor}</Text>
                        </div>
                        <div style={{flex: 1}}></div>
                        {/* <div>
                            {installingExtensionName !== v?.name && !isExtensionInstalled(v?.name) && !installedExtensions.indexOf(v?.name) > -1 && <Button icon="download" text="Install" small intent={Intent.PRIMARY} onClick={(e) => {
                                dispatch(setInstallingExtension(v.name));
                                e.stopPropagation();
                                e.preventDefault();
                            }}/>}
                            {installingExtensionName === v?.name && <Button icon="cross" text="Cancel" small intent={Intent.NONE} onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                dispatch(clearInstallingExtension());
                            }}/>}
                            
                        </div> */}
                        
                    </div>
                    {installingExtensionName === v?.name && <ProgressBar intent={Intent.PRIMARY}/>}
                </div>

        </div>)}
    </div>
  );
}

export default ResultItem;