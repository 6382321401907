import React, { lazy } from 'react';
import {
    DockviewReact,
    DockviewDefaultTab
} from 'dockview';
import ActivityBar from './ActivityBar'
import 'dockview/dist/styles/dockview.css';
import Welcome from '../welcome';
import StatusBar from './StatusBar';
import { Icon } from '@blueprintjs/core';
import * as Blueprint from '@blueprintjs/core';
import * as ReactLeaflet from 'react-leaflet';
import * as DataTable from 'ag-grid-react';
import * as Dockview from 'dockview';
import MonacoEditor from 'react-monaco-editor';
import { Observable, from, Subject, share } from 'rxjs';
import { set } from 'date-fns-jalali';
import { FullscreenControl } from "react-leaflet-fullscreen";
import  HelpSideBar from '../help/HelpSideBar';
import ExtensionSidebar from '../extensions/ExtensionSideBar';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as FAFreeBrandSVGIcons from '@fortawesome/free-brands-svg-icons';
import * as FAFreeSolidSVGIcons from '@fortawesome/free-solid-svg-icons';
import { EXTENSION_ASSETS_URL, fetchUserExtensions } from '../../services/api';
import { useSelector } from 'react-redux';
import ExtensionInfo from '../extensions/ExtensionInfo'
import { gl } from 'date-fns/locale';
import { registerExtension} from '../extensions/extensions-actions';
import { useDispatch } from 'react-redux';
import Output from './Output';

const UILayout = (props) => {
    const { userDetails } = useSelector(state => state.session);
    const GROUPS = ['primary-sidebar', 'main-panel', 'bottom-panel', 'secondary-sidebar', 'status-bar', 'active-bar'];
    const [api, setApi] = React.useState();
    const dispatch = useDispatch();

    const [refreshKey, setRefreshKey] = React.useState(0);
    // let layoutEvent = null;
    const [ layoutEvent, setLayoutEvent ] = React.useState(null);
    const [ commands$, setCommands$ ] = React.useState({
        'layout-ready': new Subject()
    });

    const [componentList, setComponentList] = React.useState({
        // activityBar: ActivityBar,
        activityBar: (props) => <ActivityBar contributions={[]} {...props} commands$={commands$} getCommands$={function () { return commands$;}}/>,
        welcomePanel: Welcome,
        statusBar: StatusBar,
        helpSideBar: HelpSideBar,
        extensionSideBar: ExtensionSidebar,
        'extension-info': ExtensionInfo,
        'bottom-panel': Output,


        // default: (props: IDockviewPanelProps<{ title: string }>) => {
        default: (props) => {
            return (
                <div style={{ padding: '20px', color: 'white' }}>
                    { //props.params.title
                    }
                </div>
            );
        },
    });

    //save layout
    // React.useEffect(() => {
    //     if(!api) {
    //         return;
    //     }
        
    //     const disposable = api.onDidLayoutChange(() => {
    //         const layout = api.toJSON();
    //         console.log('layout:', layout);
    //         // localStorage.setItem('bodapro_layout', JSON.stringify(layout));
    //     });
        
    //     return () => disposable.dispose();
    // }, [api]);

    //globalThis
    globalThis['commands$'] = globalThis['commands$'] || commands$;
    globalThis['bodaproComponents'] = globalThis['bodaproComponents']  || componentList;

    //installed extensions
    globalThis['BODAPRO_EXTENSIONS'] = globalThis['BODAPRO_EXTENSIONS'] || [];

    //Panel components
    let components = {};
    let _extComponents = {};
    let _commands = {};
    let tabs = {};
  
        //setup bodaContext
    //---------------------------------------------------
    window['bodapro-context'] = {

        updateExtensionList : (exntesionName) => {
           if(globalThis['BODAPRO_EXTENSIONS'].indexOf(exntesionName) === 0) globalThis['BODAPRO_EXTENSIONS'].push(exntesionName);
        },

        getExntesionList: () => {
            return globalThis['BODAPRO_EXTENSIONS'];
        },

        // activateExtension:  async (extensionName)  => {
        //     console.log('activating extension:', extensionName);

        //     //web
        //     if(bodapro.platformType === 'web'){
        //         const entryURL  = `${EXTENSION_ASSETS_URL}/${ext.extension.name}/entry.js`;
        //         const icon = `${EXTENSION_ASSETS_URL}/${ext.extension.name}/assets/${ext.extension.name}.svg`;
        //         const someThing = await import(/* webpackIgnore: true */ entryURL);

        //         _contributions.push({
        //             'id': ext.extension.identifier, //extensionPackage.id,
        //             'name': ext.extension.name, //extensionPackage.name,
        //             'label': ext.extension.name, //extensionPackage.label,
        //             'version': ext.version, //extensionPackage.version,
        //             'description': ext.description, //extensionPackage.description,
        //             'icon': icon, //extensionPackage.icon.endsWith('.svg') ? bodapro.path.join(extDir, extensionPackage.icon) : extensionPackage.icon,
        //         });

        //         const importExt = someThing.default(); //----

        //         setCommands$({ ...commands$, ..._commands});
        //         globalThis['commands$'] = { ...globalThis['commands$'], ..._commands};
        //         setActivityBarContributions(_contributions);
        //     }

        // },

        //bts api 
        api: bodapro,

        commands$: commands$,

        registerCommand: function (name, callback) {

            if(name in  globalThis['commands$']) {
                return;
            }


            const observable = new Subject();
            observable.subscribe((args) => {
                if( typeof callback === 'function') callback(args);
            });

            setCommands$({
                ...commands$,
                [name]: observable
            });

            _commands[name] = observable;

            setCommands$({ ...commands$, ..._commands});
            // console.log('_commands:', _commands);
            globalThis['commands$'] = { ...globalThis['commands$'], ..._commands};
        },

        registerComponent: function (component) {
            components[component.id] = (panelProps) => <component.component 
                ReactLeaflet={ReactLeaflet} 
                FullscreenControl={FullscreenControl}
                MonacoEditor={MonacoEditor}
                FontAwesomeIcon={FontAwesomeIcon}
                FAFreeSolidSVGIcons={FAFreeSolidSVGIcons}
                FAFreeBrandSVGIcons={FAFreeBrandSVGIcons}
                panelProps={panelProps}
                />;

            setComponentList({ ...componentList, ...components});
            globalThis['bodaproComponents'] = { ...globalThis['bodaproComponents'], ...components};
        },

        //trigger command
        triggerCommand: function (commandName, args) {
            if(globalThis.commands$[commandName] !== undefined) globalThis.commands$[commandName].next(args);
        },

        //subscribe to command
        subscribeToCommand: function (commandName, callback) {

            // if(commandName in globalThis.commands$) return;

            //add delay to make sure the command is available
            setTimeout(() => {
                if(globalThis.commands$[commandName] !== undefined) globalThis.commands$[commandName].subscribe((args) => {
                    if( typeof callback === 'function') callback(args);
                });
            }, 10);

        },

        addPanel: function ({id, title, componentName, groupId, extraProps, params, replacePanel}) {
            if(layoutEvent.api.getPanel(id) !== undefined && replacePanel !== true) {
                layoutEvent.api.getPanel(id).api.setActive();
                return;
            }

            //get primary side panel group 
            const panelGroup = (groupId !== undefined && GROUPS.indexOf(groupId) > -1) ? layoutEvent.api.getPanel(groupId).api.group : layoutEvent.api.getPanel('primary-sidebar').api.group;

            //clear current panel
            if(replacePanel && layoutEvent.api.getPanel(id) !== undefined){
                const _panel = layoutEvent.api.getPanel(id);
                layoutEvent.api.removePanel(_panel);
            }

            const newPanel = layoutEvent.api.addPanel({
                id: id,
                component: componentName,
                title: title,
                position: { referenceGroup: panelGroup },
                ...extraProps,
                params
            });

        } 

    };
    //eof: bodapro context---------------------------------------------------------------------------------------------------


    
    const fn = async () => {

        if(layoutEvent === null) return;

        // get extension list  
        let extensions =  [];

        // let _extComponents = {};
        // let _commands = {};
        let _contributions = [];

        //---WEB extensions
        //--------------------------------------------------------
        console.log('bodapro.platformType:', bodapro.platformType);
        if(bodapro?.platformType === 'web'){
            // const ext = await import('file:./extensions/extension1/dist/extension1.js');
            extensions = await fetchUserExtensions(userDetails?.token);    
            console.log('web extensions: ', extensions);
            extensions.forEach( async ext => {
                    const entryURL  = `${EXTENSION_ASSETS_URL}/${ext.extension.name}/entry.js`;
                    const icon = `${EXTENSION_ASSETS_URL}/${ext.extension.name}/assets/${ext.extension.name}.svg`;
                    const someThing = await import(/* webpackIgnore: true */ entryURL);

                    _contributions.push({
                        'id': ext.extension.identifier, //extensionPackage.id,
                        'name': ext.extension.name, //extensionPackage.name,
                        'label': ext.extension.name, //extensionPackage.label,
                        'version': ext.version, //extensionPackage.version,
                        'description': ext.description, //extensionPackage.description,
                        'icon': icon, //extensionPackage.icon.endsWith('.svg') ? bodapro.path.join(extDir, extensionPackage.icon) : extensionPackage.icon,
                    });

                    const importExt = someThing.default(); //----

                    setCommands$({ ...commands$, ..._commands});
                    globalThis['commands$'] = { ...globalThis['commands$'], ..._commands};
                    setActivityBarContributions(_contributions);

                    //update the extension list
                    globalThis['BODAPRO_EXTENSIONS'].push(ext.extension.name)
            //---
            });


            //update the component list
            setComponentList(globalThis['bodaproComponents']);

            return;
        }
        //end of web|online ------------------------------------------------------ONLINE
        if(extensions === undefined) return;

        //---desktop extensions
        //--------------------------------------------------------
        extensions = await bodapro.getInstalledExtensions();
        console.log('extensions:', extensions);
        let idx = 0;
        for (const extension of extensions) {
            idx++;
            //const packageJson = path.join(extension, 'package.json');
            const extensionPackage = bodapro.require(extension);
            const extDir = bodapro.path.dirname(extension);

            const entryFile = bodapro.path.join(extDir,extensionPackage.entry);

            //check if entry files exists 
            if(!bodapro.fs.existsSync(entryFile)) continue;
            _contributions.push({
                'id': extensionPackage.id,
                'name': extensionPackage.name,
                'label': extensionPackage.label,
                'version': extensionPackage.version,
                'description': extensionPackage.description,
                'icon': extensionPackage.icon.endsWith('.svg') ? bodapro.path.join(extDir, extensionPackage.icon) : extensionPackage.icon,
            });
            
            // (async () => {
                try{
                    const someThing = await import(/* webpackIgnore: true */`file:${entryFile}`);
                    const importExt = someThing.default();
                // importExt.activate()
                }catch(e){
                    console.log('error:', e);
                }
            // })();

                //update the extension list
                globalThis['BODAPRO_EXTENSIONS'].push(extensionPackage.name)
                dispatch(registerExtension(extensionPackage.name));
        }

        setCommands$({ ...commands$, ..._commands});
        globalThis['commands$'] = { ...globalThis['commands$'], ..._commands};
        setComponentList(globalThis['bodaproComponents']);

        setActivityBarContributions(_contributions);
    }

    //activityBar contributions 
    const [ activityBarContributions, setActivityBarContributions ] = React.useState([]);

    //Load extensions 
    React.useEffect(  () => {
        fn();
    }, [layoutEvent !== null]);  


React.useEffect(() => {

    if(layoutEvent === null){
        return;
    }
    const actvitiyBarPanel = layoutEvent.api.getPanel('active-bar');
    actvitiyBarPanel.api.updateParameters({
        contributions: activityBarContributions,
        'keyA': 'Testing A'
    });

}, [activityBarContributions.length, layoutEvent !== null]);

    const PanelTab = (props) => {
        const onContextMenu = (event) => {
            event.preventDefault();
        };
        return <DockviewDefaultTab onContextMenu={onContextMenu} {...props} hideClose={true} />;
    };

    const BottomTab = (props) => {
        const onContextMenu = (event) => {
            event.preventDefault();
            // alert('context menu');
        };
        return <DockviewDefaultTab onContextMenu={onContextMenu} {...props} hideClose={true}/>;
    };

    const RightHeaderActions = (props) => {
        return (<div 
            className='bp5-dark'
            style={{
                padding: 5,
                paddingTop: 10
            }}
            >
            <Icon icon="more" intent="secondary" />
        </div>);
    }

    //panel contents 
    components = {
        // activityBar: ActivityBar,
        activityBar: (props) => <ActivityBar contributions={[]} {...props} commands$={commands$} getCommands$={function () { return commands$;}}/>,
        welcomePanel: Welcome,
        statusBar: StatusBar,
        'extension-info': ExtensionInfo,

        // default: (props: IDockviewPanelProps<{ title: string }>) => {
        default: (props) => {
            return (
                <div style={{ padding: '20px', color: 'white' }}>
                    {props.params.title}
                </div>
            );
        },
    };

    //penal tabs 
    // let tabComponents = {
    //     panelTab: PanelTab,
    //     welcomeTab: PanelTab,
    //     outputTab: BottomTab
    // };

    const [ tabComponents, setTabComponents ] = React.useState({
        panelTab: PanelTab,
        welcomeTab: PanelTab,
        outputTab: BottomTab,
        extensionSideBar: PanelTab,
        docsSideBar: PanelTab,
        noCloseTab: PanelTab,
    });

    // const onReady = (event: DockviewReadyEvent) => {
    const onReady = (event) => {
        setApi(event.api);

        //set layout dockview event
        // layoutEvent = event;
        setLayoutEvent(event);

        //activitybar
        const activeBar = event.api.addPanel({
            id: 'active-bar',
            component: 'activityBar',
            params: {
                title: 'Active Bar',
                contributions: []
            },
        });
        activeBar.group.locked = true;
        activeBar.group.header.hidden = true;
        activeBar.api.group.api.setConstraints({
            maximumWidth: 60,
            minimumWidth: 60,
        });
  
        //status bar 
        //----------------------------
        const statusBar = event.api.addPanel({
            id: 'status-bar',
            component: 'statusBar',
            title: 'Status',
            params: {
                title: 'Primary sidebar'
            },
            position: { referencePanel: 'active-bar', direction: 'below' },
        });
        statusBar.group.locked = true;
        statusBar.group.header.hidden = true;
        statusBar.api.group.api.setConstraints({
            maximumHeight: 30,
            minimumHeight: 30,
        });

        //primary sidebar
        //----------------------------
        const primarySidebar = event.api.addPanel({
            id: 'primary-sidebar',
            component: 'extensionSideBar',
            title: 'EXTENSIONS',
            tabComponent: 'extensionSideBar', 
            params: {
                title: 'Primary sidebar'
            },
            position: { referencePanel: 'active-bar', direction: 'right' },
        });
        primarySidebar.api.setSize({width: 300});
        primarySidebar.group.locked = true;
        primarySidebar.group.hideClose = true;

        //main panel
        //----------------------------
        const mainPanel = event.api.addPanel({
            id: 'main-panel',
            component: 'welcomePanel',
            title: 'Welcome',
            params: {
                title: 'Welcome',
            },
            tabComponent: 'welcomeTab', 
            position: { referencePanel: 'primary-sidebar', direction: 'right' },
        });
        mainPanel.group.locked = true;
        mainPanel.group.hideClose = true;

        //secondry sidebar
        //----------------------------
        const secondarySidebar = event.api.addPanel({
            id: 'secondary-sidebar',
            component: 'helpSideBar',
            title: 'Documentation',
            tabComponent: 'noCloseTab',
            params: {
                title: 'Documentation',
            },
            position: { referencePanel: 'main-panel', direction: 'right' },
        });
        secondarySidebar.api.setSize({width: 300});

        //bottom panel
        //----------------------------
        const bottomPanel = event.api.addPanel({
            id: 'bottom-panel',
            component: 'bottom-panel',
            title: 'OUTPUT',
            tabComponent: 'outputTab', 
            params: {
                title: 'Bottom Panel',
            },
            position: { referencePanel: 'main-panel', direction: 'below' },
            
        });
        bottomPanel.api.setSize({height: 100});

        //set the parimary and secondary sidebar sizes again
        //----------------------------
        primarySidebar.api.setSize({width: 400});
        secondarySidebar.api.setSize({width: 300});

    };


    return  <DockviewReact
        components={componentList}
        tabComponents={tabComponents}
        onReady={onReady}
        className={props.theme || 'dockview-theme-dark'}
        rightHeaderActionsComponent={RightHeaderActions}
    />
}

export default UILayout;