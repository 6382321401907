import React from 'react';
import { Alert, Button, Icon, Intent, ProgressBar, Spinner, Tab, Tabs, Tag } from '@blueprintjs/core';
import PanelWrapper from '../layoutv2/PanelWrapper';
import { getExtensionConfig, DOWNLOAD_ASSETS_URL, EXTENSION_ASSETS_URL } from '../../services/api'
import Markdown from 'react-markdown'
import rehypeHighlight from 'rehype-highlight';
import rehypeRaw from 'rehype-raw';
import { setInstallingExtension, clearInstallingExtension, unregisterExtension } from './extensions-actions';
import { useDispatch, useSelector } from 'react-redux';
import { config } from 'process';
import ActivityBarIcon2 from '../../components/ActivityBarIcon2';
import { set } from 'date-fns-jalali';


const ExtensionInfo = ({ params }) => {
    const [info, setInfo] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [extIconUrl, setExtIconUrl] = React.useState(null);
    const [extConfig, setExtConfig] = React.useState(null);
    const [selectedTab, setSelectedTab] = React.useState('details');
    const [details, setDetails] = React.useState(null);
    const [changelog, setChangelog] = React.useState(null);
    const { installingExtensionName, installingExtension } = useSelector(state => state.extensions);
    const [installUninstall, setInstallUninstall] = React.useState(null);

    //acknowledge extension installation
    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState(null);
    const [installError, setInstallError] = React.useState(null);

    const dispatch = useDispatch();

    const handleTabChange = (tabId) => setSelectedTab(tabId);

    const isExtensionInstalled = (name) => {
        return globalThis['BODAPRO_EXTENSIONS'].indexOf(name) > -1;
    }

    const addExtension = async () => {
        dispatch(setInstallingExtension(extConfig.name));
        const result = await bodapro.installExtension({
            extension: extConfig,
            url: `${DOWNLOAD_ASSETS_URL}/${extConfig.name}.zip`
        });

        if(result?.status === 'success'){
            setShowAlert(true);
            setAlertMsg('Extension installed successfully. Restart/reload BodaPro to effect changes');

            //@todo: activate
            // BodaProContext.activateExtension(extConfig.name);
            // window['bodapro-context'].activateExtension(extConfig.name);
        }else{
            //show error message
            setInstallError('Installation failed');
        }

        dispatch(clearInstallingExtension());
    }

    const uninstallExtension = async (extName) => {
        dispatch(setInstallingExtension(extConfig.name));
        try{
            const result = await bodapro.uninstallExtension({extension: extConfig});
            dispatch(unregisterExtension(extName));
            setShowAlert(true);
            setAlertMsg('Extension uninstalled successfully. Restart/reload BodaPro to effect changes');
            
        }catch(e){
            console.log('uninstall error:', e);
            dispatch(clearInstallingExtension());
            setInstallError('Uninstallation failed');
        }

        dispatch(clearInstallingExtension());
    }

    React.useEffect(() => {

        const fn = async () => {
            try {
                const config = await getExtensionConfig(params.extension);
                console.log('config:', config);
                setExtConfig(config);
                setExtIconUrl(config.icon);

                //get details 
                const resp = await fetch(`${EXTENSION_ASSETS_URL}/${params.extension}/docs/details.md`);
                const detailsText = await resp.text();
                setDetails(detailsText);
                
                //change log 
                const resp2 = await fetch(`${EXTENSION_ASSETS_URL}/${params.extension}/docs/changelog.md`);
                const changelogText = await resp2.text();
                setChangelog(changelogText);

            } catch (e) {

            }

            setLoading(false);
        }

        fn();

    }, [params])

    return <PanelWrapper style={{marginRight: 100}}>

        {loading || extConfig === null ? <Spinner color={Intent.PRIMARY} /> : <>
            <div style={{ display: "flex", flexDirection: "row", marginBottom: 20 }}>
                <div style={{ padding: 5}}>
                    {/* {extIconUrl ? (<img src={`${EXTENSION_ASSETS_URL}/${params.extension}/${extIconUrl}`} width={100} />) : (<Icon icon="cube" size={100} intent="secondary" />)} */}
                    {extIconUrl ? (<ActivityBarIcon2 icon={`${EXTENSION_ASSETS_URL}/${params.extension}/${extIconUrl}`} size={100} />) : (<Icon icon="cube" size={100} intent="secondary" />)}
                </div>
                <div>
                    <div style={{ marginBottom: 5 }}><span style={{ fontSize: 48 }}>{extConfig.label}</span> <span><Tag minimal round>{extConfig.version}</Tag></span></div>
                    <div style={{ marginBottom: 5 }}>{extConfig.vendor} | <Icon icon="download" intent={Intent.PRIMARY} /> 1,000,566</div>
                    <div style={{ marginBottom: 5 }}>{extConfig.description}</div>
                    <div style={{marginBottom: 10}}></div>

                    {installingExtensionName !== extConfig.name && !isExtensionInstalled(extConfig.name) && <Button icon="download" text="Install" small intent={Intent.PRIMARY} onClick={() => {
                        setInstallUninstall('Install');
                       
                    }}
                    disabled={installingExtensionName === extConfig.name}
                    />} &nbsp;

                    {installingExtensionName === extConfig.name && <Button icon="cross" text="Cancel" small intent={Intent.NONE} onClick={() => {
                        dispatch(clearInstallingExtension());
                   
                     }} />}

                    {isExtensionInstalled(extConfig.name) && <Button icon="trash" text="Uninstall" small intent={Intent.DANGER} 
                        onClick={() => {
                            setInstallUninstall('Uninstall');
                        }}
                    />}

                </div>
            </div>

            {installingExtensionName ===  extConfig.name && <ProgressBar intent={Intent.PRIMARY}/>}


            <div>
                <Tabs id="extension-info" onChange={handleTabChange} selectedTabId={selectedTab} large>
                    <Tab id="details" title="DETAILS" panel={(prps) => <Markdown rehypePlugins={[rehypeHighlight]}>{details}</Markdown>} />
                    <Tab id="changelog" title="VERSION HISTORY" panel={(prps) => <Markdown rehypePlugins={[rehypeHighlight]}>{changelog}</Markdown>}/>
                </Tabs>
                {selectedTab === 'details' && <Markdown rehypePlugins={[rehypeHighlight, rehypeRaw]} 
                urlTransform={uri =>uri.startsWith("http") ? uri : `${EXTENSION_ASSETS_URL}/${params.extension}/docs/${uri}`}>{details}
                </Markdown>}

                {selectedTab === 'changelog' && <Markdown rehypePlugins={[rehypeHighlight]}>{changelog}</Markdown>}
            </div>
        </>}


        <Alert
            cancelButtonText="Cancel"
            confirmButtonText="Reload Now"
            isOpen={showAlert}
            onClose={() => { 
                setShowAlert(false);
                setInstallError(null);
                setAlertMsg(null);
            }}
            icon="cube"
            intent={installError ? Intent.DANGER : Intent.PRIMARY }
            onConfirm={() => {
                window.location.reload();
            }}
        >
            <p>{alertMsg}</p>
        </Alert>

        <Alert
            cancelButtonText="Cancel"
            confirmButtonText={installUninstall === "Install" ? "Install" : "Uninstall"}
            isOpen={installUninstall !== null}
            onClose={() => { 
                setInstallUninstall(null);
            }}
            icon="cube"
            intent={installError ? Intent.DANGER : Intent.PRIMARY }
            onConfirm={() => {      

                dispatch(setInstallingExtension(extConfig.name));

                if(installUninstall === 'Uninstall') uninstallExtension(extConfig.name);

                if(installUninstall === 'Install') { 
                    addExtension();
                }

                setInstallUninstall(null);
            }}
        >
            <p>{installUninstall === 'Install' && "Confirm installation of extension."}</p>
            <p>{installUninstall === 'Uninstall' && "Confirm uninstallation of extension."}</p>
        </Alert>
    </PanelWrapper>
}

export default ExtensionInfo;