import * as React from 'react';
import { Text } from '@blueprintjs/core';
import { useSelector, useDispatch } from 'react-redux';
import { addLogMessage } from '../../redux/actions/log-actions';

const Output = () =>{
    const dispatch = useDispatch();
    const { messages } = useSelector(state => state.log);

    React.useEffect(() => {
        bodapro.listenOn('log.add', (event, message) => {
            // console.log('log.add: message:', message, ' event:', event);
            
            dispatch(addLogMessage(message.message));
        });
    }, []);


    return (
        <div className="bp5-dark p-2" style={{
            overflowY: "auto",
            height: "100%",
            fontFamily: "monospace",
        }}>
           {messages && messages.map((msg, idx) => <div key={idx} style={{marginBottom: '2px'}}><Text>{msg}</Text></div>)}
        </div>
    )
}

export default Output