export const ADD_LOG_MESSAGE = 'ADD_LOG_MESSAGE';
export const CLEAR_LOG_MESSAGES = 'CLEAR_LOG_MESSAGES';

export const addLogMessage = (message) => {
    return {
        type: ADD_LOG_MESSAGE,
        message
    };
}

export const clearLogMessages = () => {
    return {
        type: CLEAR_LOG_MESSAGES
    };
}
